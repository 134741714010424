@import "../../constants/themes.scss";

.eMat-tabs {
  .ant-tabs-nav {
    background-color: inherit;
    margin-bottom: 0px;
    .ant-tabs-nav-wrap {
      .ant-tabs-ink-bar {
        @include customize(theme) {
          border-bottom: 5px solid getValue(buttonPrimaryBackground);
        }
      }
    }
    .ant-tabs-nav-list {
      .ant-tabs-tab {
        background-color: inherit;
        margin: 0;
        font-family: "Roboto Medium";
        font-weight: 500;
        font-size: 22px;
        text-transform: uppercase;
        padding: 25px;
        @media (max-width: 576px) {
          font-size: 18px;
          padding: 16px;
        }
      }
    }
  }
  .ant-tabs-content-holder {
    position: relative;
    padding: 15px;
    @media (min-width: 576px) {
      padding: 25px;
    }
  }
}
