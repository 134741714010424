@import "../../../constants/themes.scss";

.auth-container {
	text-align: center;
	min-height: 100vh;
	min-width: 100vw;
	display: flex;
	.logo-container {
		margin-bottom: 50px;
	}
	.auth-content {
		margin: auto;
		width: 100%;
		width: 450px;
		padding: 20px;
		@media (min-width: 576px) {
			width: 450px;
		}
	}
	.error-msg {
		color: #fff;
		padding: 6px;
		border-radius: 5px;
		font-family: "Roboto";
		font-size: 15px;
		@media(max-width:576px) {
			font-size: 11px;
		} 
		p {
			margin: 0;
		}
		@include customize(theme) {
			background-color: getValue(error);
		}
	}
}
