$themes: (
  default: (
    backgroundLight-1: #f5f5f5,
    backgroundLight-2: #fff,
    backgroundLight-3: #fafafa,
    backgroundLight-4: #E5E5E5,
    theme-dark-0: #0a1d2c,
    theme-dark-1: #162f43,
    theme-dark-2: #1e3a4f,
    theme-dark-3: #23425a,
    theme-dark-4: #2d5675,
    theme-dark-5: #305776,
    theme-dark-8: #666666,
    theme-dark-9: #5e5d5d,
    theme-soft-1: #c4c4c4,
    inputBorder-1: #a1a1a1,
    inputBorder-2: #a2a2a2,
    inputBackground: #fff,
    primary-0: #17a58d,
    primary-1: #1cc3a7,
    buttonPrimaryBackground: #1cc3a7,
    buttonPrimaryColor: #fff,
    buttonTextColor: #1cc3a7,
    buttonTextBorderHover: #1cc3a7,
    linkColor: #1cc3a7,
    error: #da262a,
  ),
);

@mixin customize($arg: theme) {
  $array: $themes;
  $prefix: theme;

  @if ($arg == theme) {
    $array: $themes;
    $prefix: theme;
  }

  @each $theme, $map in $array {
    .#{$prefix}-#{$theme} & {
      $array-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($array, $theme), "#{$key}");
        $array-map: map-merge(
          $array-map,
          (
            $key: $value,
          )
        ) !global;
      }

      @content;
      $array-map: null !global;
    }
  }
}

@function getValue($key) {
  @return map-get($array-map, $key);
}
