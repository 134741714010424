@import "../../constants/themes.scss";

.content {
  position: relative;
  padding: 10px;
  min-height: 350px;
  height: calc(100vh - 64px);
  overflow: auto;
  @media (min-width: 576px) {
    padding: 20px;
  }
}

.commissions-container {
  position: relative;

  .commissions-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h2 {
      font-weight: 700;
    }

    #add-alert {
      padding-right: 20px;
    }
  }
  .filter {
    display: flex;
    flex-direction: row;
    @media (max-width: 576px) {
      flex-direction: column;
      text-align: start;
      margin-bottom: 10px;
    }
  }

  .filter-positions-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;

    .total-positions {
      margin: 5px;
    }

    .filters-accounts {
      width: 200px;
      min-width: 150px;
      margin: 5px;
      @media (max-width: 576px) {
        width: auto;
      }
    }
    .filters-date {
      margin: 5px;
      @media (max-width: 576px) {
        width: auto;
      }
    }

    @media (max-width: 576px) {
      display: inline;
    }
  }

  .totals-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    position: absolute;
    left: 5px;
    bottom: -60px;
    margin-bottom: 20px;
    justify-content: space-between;
    width: 200px;

    p {
      font-weight: 600;
      font-size: 15px;
    }
    .total {
      color: red;
    }
  }

  .download-csv {
    width: 210px;
    min-width: 100px;
    margin: 8px;
    margin-block: 5px;
    @media (max-width: 576px) {
      width: auto;
    }
  }

}
