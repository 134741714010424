@import "./../../../constants/themes.scss";

.notifications-box {
  padding: 10px;
  width: 300px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border-radius: 2%;

  @media (max-width: 576px) {
    width: 100%;
  }

  .notification {
    padding-left: 5px;
    margin-bottom: 2px;
    font-size: medium;

    .title {
      font-weight: 600;
      font-family: "Roboto";
      color: #5e5d5d;
    }

    .message {
      margin-bottom: 2px;
      color: #5e5d5d;
    }
  }

  .readed {
    border-bottom: solid;
  }

  .unreaded {
    border-bottom: solid;
  }
}
