@import "../../constants/themes.scss";

.content {
  position: relative;
  padding: 10px;
  min-height: 350px;
  height: calc(100vh - 64px);
  overflow: auto;
  @media (min-width: 576px) {
    padding: 20px;
  }
}
.role-select {
  min-width: 250px;
  text-align: start;
  @media (max-width: 576px) {
    min-width: 120px;
  }
  margin-block: 5px;
}

.clients-container {
  position: relative;

  .clients-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-weight: 700;
    }

    #add-alert {
      padding-right: 20px;
    }
  }

  .filter-positions-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-inline: 8px;
 

    @media (max-width: 576px) {
      display: inline;
    }
  }



}
