@import "../../../../constants/themes.scss";

.ant-table-expanded-row {
  top: -20px;
}
.strike-table {
  #strike-table {
    margin-left: 0;
    margin-top: 0px;
    margin-bottom: 0px;
    max-width: 100%; //NEW
  }
  table {
    box-sizing: initial !important;
    border-collapse: collapse !important;

    td,
    th {
      text-align: center;
    }
    thead {
      tr {
        @include customize(theme) {
          th {
            background-color: getValue(primary-1);
            color: getValue(backgroundLight-2);
          }
          &:first-child {
            th {
              background-color: getValue(primary-0);
            }
          }
        }
      }
    }
    thead,
    tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
      cursor: default !important;
    }

    tbody {
      display: block;
      max-height: 300px !important;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        @include customize(theme) {
          background-color: getValue(backgroundLight-1);
        }
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        @include customize(theme) {
          background-color: getValue(primary-1);
        }
      }

      @media (min-width: 1024px) {
        max-height: 400px !important;
      }

      tr td {
        border: 0px !important;
        font-family: "Roboto" !important;
        color: #666666;
      }
      tr {
        display: table;
        box-shadow: none !important;
        border-radius: 0px !important;

        &:nth-child(even) {
          @include customize(theme) {
            background-color: getValue(backgroundLight-1);
          }
        }

        td .strike-tag {
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
          border-radius: 5px;
          color: #fff;
          padding: 6px 12px;
          font-family: "Roboto Bold";
          font-size: 14px;
          margin-right: 0;
          border: 0;
          cursor: pointer;
          transition: background-color 150ms ease-in-out;
          @include customize(theme) {
            background-color: getValue(primary-1);
          }

          &:hover {
            @include customize(theme) {
              background-color: getValue(primary-0);
            }
          }

          @media (min-width: 576px) {
            font-size: 17px;
          }
          @media (min-width: 1024px) {
            padding: 6px 33px;
          }
        }
      }
    }
  }
}
