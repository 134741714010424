@import "../../constants/themes.scss";

.buy-sell-modal {
	@media (min-width: 577px) {
		width: 570px;
	}
	&.background {
		color: white;
		.ant-modal-close-x {
			color: white;
		}
		&.close {
			.ant-modal-content {
				background-color: #2d5675;
			}
		}
		.ant-modal-content {
			background-color: #1e3a4f;
		}
	}
	.ant-modal-content {
		.ant-modal-body {
			padding: 0;
			.eMat-tabs.operations-tabs {
				margin-top: 20px;
				.ant-tabs-nav,
				.ant-tabs-tab {
					background: inherit;
					.ant-tabs-tab-btn {
						@include customize(theme) {
							color: getValue(theme-dark-8) !important;
						}
					}
				}
				.ant-tabs-nav-wrap {
					.ant-tabs-nav-list {
						width: 100%;
						justify-content: unset;
						.ant-tabs-tab {
							width: 50%;
							justify-content: center;
							background-color: inherit;
							.ant-tabs-tab-btn {
								opacity: 1;
							}
							&.ant-tabs-tab-active, &:not(.ant-tabs-tab-active):hover {
								background-color: #e9fcf9;
							}
						}					
					}
				}
				.ant-tabs-content-holder {
					background-color: inherit;
					padding: 0;
				}
				.ant-tabs-nav::before {
					border: none;
				}
			}
		}
	}
	.ant-modal-footer {
		padding: 0;
	}
}
