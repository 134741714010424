.order-card {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.active-order-card {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border-color: red;
  border-width: 3px;
}