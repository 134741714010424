@import "../../constants/themes.scss";

#components-layout-custom-trigger {
  .trigger {
    margin: 0 24px;
    font-size: 18px;
    cursor: pointer;
    transition: color 0.3s;
    color: white;

    @media (max-width: 576px) {
      font-size: 14px;
    }
    svg {
      font-size: 25px;
      @media (max-width: 576px) {
        font-size: 21px;
      }
    }
  }
  .trigger-with-bg {
    @extend .trigger;
    padding: 8px;
    margin: 0 calc(24px - 8px);
    height: fit-content;

    @include customize(theme) {
      border-radius: 50%;
      background-color: getValue(theme-dark-0);
    }
  }

  .close-sidebar-overlay {
    position: fixed;
    inset: 0;
    z-index: 1;
    width: 100vw;
    height: 100%;

    @include customize(theme) {
      background-color: change-color($color: getValue(theme-dark-0), $alpha: 0.7);
    }
  }
}

#components-layout-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

#components-layout-custom-trigger {
  background: #162f43;
  min-height: 100vh;

  .site-layout.ant-layout {
    background-color: white;
    position: relative;

    .site-layout-background {
      background: #162f43;
      color: white;
    }
  }
  .site-layout header {
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
    #user-menu-container {
      display: flex;
      .notifications-bell {
        position: relative;
        display: flex;
        align-self: center;
      }
      .user-menu {
        padding: 0 20px;
        display: flex;
        align-self: center;
        cursor: pointer;
      }
      .user-name {
        margin-right: 20px;
      }
      .ant-dropdown-menu {
        @include customize(theme) {
          background-color: getValue(theme-dark-5);
          li {
            color: getValue(backgroundLight-2);
            &:hover {
              background-color: getValue(theme-dark-3);
            }
          }
        }
      }
    }
    .selected-page {
      display: inline;
      font-family: "Roboto";
      font-weight: 700;
    }
  }
  .ant-layout-sider {
    background: #162f43;
    overflow-y: auto;

    .logo-container {
      height: 64px;
      display: flex;
      padding-left: 20px;
      align-items: center;
      img {
        height: 40px;
      }
    }
    .ant-menu.ant-menu-root.ant-menu-inline {
      font-family: "Roboto";
      font-weight: 500;
      font-size: 16px;
      background: inherit;

      @media (min-width: 992px) {
        font-size: 18px;
      }
      @media (min-width: 1200px) {
        font-size: 20px;
      }
    }
    .ant-menu {
      background: #162f43;

      .ant-menu-item {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 8px 16px;
        min-height: 50px;

        @include customize(theme) {
          &-selected {
            background: inherit;
            border-right: 6px solid getValue(buttonPrimaryBackground);
          }
        }

        .anticon {
          display: none;

          @media (min-width: 992px) {
            display: inline-block;
          }
        }
      }
      &.ant-menu-inline-collapsed {
        .ant-menu-item {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 16px;

          .ant-menu-title-content {
            display: none;
          }

          &-selected {
            border-right-width: 0;

            @include customize(theme) {
              .anticon {
                color: getValue(buttonPrimaryBackground);
              }
            }
          }

          .anticon {
            @media (min-width: 992px) {
              font-size: 24px;
            }
          }
        }
      }
    }

    @media (max-width: 992px) {
      &:not(.ant-layout-sider-collapsed) {
        position: absolute;
        z-index: 2;
        inset: 0;
        flex: 1 !important;
        max-width: min(70vw, 300px) !important;
        min-width: min(70vw, 300px) !important;
        width: min(70vw, 300px) !important;
      }

      &.ant-layout-sider-collapsed {
        position: absolute;
        z-index: 2;
        inset: 0;
        min-width: 0 !important;
        width: 0 !important;
        flex: 0 !important;
        overflow: hidden;
      }
    }
    .sider-trigger {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 17px;

  @media (max-width: 576px) {
    font-size: 13px;
  }
}
