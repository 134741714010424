@import "../../../constants/themes.scss";

.options-container {
  //50px(paddingY tabholder) + 62px tabs nav  + 64px header
  min-height: calc(100vh - (30px + 62px + 64px));

  @media (min-width: 577px) {
    //50px(paddingY tabholder) + 86px tabs nav  + 64px header
    min-height: calc(100vh - (50px + 86px + 64px));
  }

  .options-prices .ant-table-expanded-row > .ant-table-cell,
  .ant-table-expanded-row .ant-table-cell-scrollbar {
    background-color: #fff;
  }
  #options-prices {
    table {
      box-sizing: border-box;
      tbody > tr {
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        cursor: pointer;
        &:hover > td {
          background: none;
        }
        td {
          color: #5e5d5d;
          font-family: "Roboto Medium";
          &.ant-table-row-expand-icon-cell svg {
            width: 37px;
          }
        }
      }
    }
  }
}
