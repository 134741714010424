.labels {
  color: white;
  font-size: 20px;
  font-weight: 600;
  font-family: "Roboto";
}

.filter {
  margin-bottom: 10px;
}

.title {
    font-weight: 600;
}

.primary {
  background-color: #1cc3a7;
  border-color: #1cc3a7;
  color: #fff;
}

.secondary {
  color: #1cc3a7;
}
