@import "../../../constants/themes.scss";

.orders-container {
	display: flex;
	position: relative;
	justify-content: space-around;
	padding: 15px;
	border-radius: 5px;
	height: 100%;
	@media (min-width: 576px) {
		padding: 25px;
	}
	@include customize(theme) {
		color: getValue(backgroundLight-2);
		background: getValue(theme-dark-5);
	}
	h3 {
		margin-bottom: 0;
	}
	.order-count {
		font-family: "Roboto";
		font-style: normal;
		font-weight: 300;
		font-size: 50px;
		line-height: 50px;
		@media (min-width: 576px) {
			font-size: 60px;
		}
		@media(max-width:576px) {
			font-size: 46px;
		} 
	}
	.divider {
		margin: 6px 0 6px 0;
		@media (min-width: 762px) {
			margin: 15px 0 15px 0;
		}
	}
	#emitted-orders,
	#received-orders {
		cursor: pointer;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
	}
	.ant-spin {
		align-self: center;
		display: flex;
	}
}
