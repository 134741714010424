@import "../../../constants/themes.scss";

.prices-container {
  #update-date {
    @include customize(theme) {
      color: getValue(backgroundLight-2);
    }
  }
  .ant-table-footer {
    @include customize(theme) {
      background-color: getValue(theme-dark-3);
      color: getValue(backgroundLight-2);
    }
  }
  #prices-table {
    table {
      thead tr {
        th {
          @include customize(theme) {
            background-color: getValue(theme-dark-3);
            color: getValue(backgroundLight-2);
          }
        }
      }
      tbody {
        tr td {
          @include customize(theme) {
            background-color: getValue(theme-dark-1);
            color: getValue(backgroundLight-2);
          }
          .ant-empty-description {
            @include customize(theme) {
              color: getValue(backgroundLight-2);
            }
          }
        }
      }
    }
  }
}
