.logo-container {
	#logo {
		margin-right: 5px;
	}
	#text-logo {
		opacity: 0;//
		animation: fade-in-text-logo 100ms forwards ease-in;
		animation-delay: 50ms;

		@keyframes fade-in-text-logo {
			from {
				opacity: 0;
			}
			to {
				opacity: 1;
			}
		}
	}
}
