@import "../../../constants/themes.scss";

.ant-tabs-tabpane {
  form {
    @include customize(theme) {
      background-color: getValue(backgroundLight);
    }
    padding: 25px 60px;
    @media (max-width: 576px) {
      padding: 15px 20px;
    }
    .col-to-market {
      margin: auto;
    }
    .open-close-switch {
      .ant-form-item-control-input-content {
        text-align: center;
        .option {
          font-family: "Roboto";
          font-weight: 400;
          font-size: 20px;
          line-height: 23.44px;
          @media (max-width: 576px) {
            font-size: 16px;
          }
          @include customize(theme) {
            color: getValue(theme-dark-8);
          }
          padding: 0 10px;
        }
      }
    }
    .buttons-container {
      text-align: center;
      .ant-form-item-control-input-content {
        display: flex;
        justify-content: space-evenly;
        #cancel {
          background: inherit;
          @include customize(theme) {
            color: getValue(theme-dark-8);
            &:hover {
              border-color: getValue(theme-dark-8);
            }
          }
        }
      }
    }
  }
  .instrument {
    background-color: #fafafa;
    line-height: 21px;
    padding: 21px;
    text-align: center;
    @include customize(theme) {
      background-color: getValue(backgroundLight);
    }
  }
}
