@import "../../../constants/themes.scss";

.spinner-in-parent {
  position: absolute;
  inset: 0;
  z-index: 1;
  display: grid;
  place-items: center;

  &.with-opacity {
    opacity: 0.95;
  }

  //Background color
  &.blue {
    @include customize(theme) {
      background-color: getValue(theme-dark-2);
    }
  }
  &.green {
    @include customize(theme) {
      background-color: getValue(primary-1);
    }
  }
  &.black {
    @include customize(theme) {
      background-color: getValue(theme-dark-0);
    }
  }
  &.white {
    @include customize(theme) {
      background-color: getValue(backgroundLight-1);
    }
  }
  //Icon color
  &.icon-blue {
    .ant-spin {
      @include customize(theme) {
        color: getValue(theme-dark-2);
      }
    }
  }
  &.icon-green {
    .ant-spin {
      @include customize(theme) {
        color: getValue(primary-1);
      }
    }
  }
  &.icon-black {
    .ant-spin {
      @include customize(theme) {
        color: getValue(theme-dark-0);
      }
    }
  }
  &.icon-white {
    .ant-spin {
      @include customize(theme) {
        color: getValue(backgroundLight-1);
      }
    }
  }
}
