.table-with-filters-container {
  .filter-container {
    display: flex;
    margin-bottom: 10px;
    flex-direction: row;
    align-items: center;
    justify-content: baseline;
    @media (max-width: 576px) {
      flex-direction: column;
      align-items: baseline;
      justify-content: center;
    }
    .select-filter {
      z-index: 1;
      min-width: 160px;
      @media (max-width: 576px) {
        min-width: 120px;
      }

      @media (max-width: 576px) {
        order: 2;
      }
    }
    .op-group {
      width: 100%;
	  margin-left: 20px;
	  display: flex;
	  justify-content: flex-end;
    }
    #update-date {
      order: 2;
      margin-left: 20px;
      color: #5e5d5d;
      @media (max-width: 576px) {
        order: 1;
        margin-left: 0px;
        margin-bottom: 5px;
      }
    }
  }
  .ant-pagination.ant-table-pagination:first-child {
    @media (max-width: 576px) {
      margin-top: 0;
    }
  }
}
