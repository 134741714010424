@import "../../../constants/themes.scss";

.spreads-container {
  min-height: calc(100vh - 201px);

  #spreads-prices {
    table {
      thead tr th {
        @include customize(theme) {
          background-color: getValue(backgroundLight-1);
          color: getValue(theme-dark-9);
        }
        &:nth-child(2) {
          padding-left: 0;
        }
      }
      tbody {
        tr {
          @include customize(theme) {
            &:nth-child(even) {
              color: getValue(theme-dark-9);
              background-color: getValue(backgroundLight-1);

              td.ant-table-column-sort {
                background-color: getValue(backgroundLight-1);
              }
            }
            td {
              color: getValue(theme-dark-9);
              &:first-child {
                width: 33px;
                padding: 0;
                padding-left: 10px;
                cursor: pointer;
                @media (max-width: 576px) {
                  width: 29px;
                }
              }
              &:nth-child(2) {
                padding-left: 0;
              }
            }
          }
        }
        .ant-table-placeholder {
          background: white;
        }
      }
    }
  }
}
