@import "../../../constants/themes.scss";

.recover-password-container {
	.recover-password-content {
		padding-top: 0;
		.logo-container {
			margin-bottom: 110px;
		}
		.buttons-container {
			.ant-form-item-control-input-content {
				display: flex;
				flex-direction: column;
				align-content: center;
				margin-top: 50px;
				#cancel {
					order: 2;
				}
				#submit {
					order: 1;
				}
				button {
					width: 100%;
				}
				@media (min-width: 576px) {
					button {
						width: 190px;
					}
					flex-direction: row;
					justify-content: space-between;
					#cancel {
						order: 1;
					}
					#submit {
						order: 2;
					}
				}
			}
		}
		.ant-result {
			padding: 0;
			padding-top: 40px;
		}
		.ant-result {
			.ant-result-extra {
				margin-top: 45px;
			}
			.ant-result-subtitle {
				font-family: "Roboto Bold";
				font-size: 20px;
				color: white;
				@media(max-width:576px) {
					font-size: 16px;
				} 
			}
		}
	}
}
