@import "../../constants/themes.scss";

.submit-container {
  #submit {
    width: 100%;
    margin-top: 35px;
  }

  .cancel {
    width: 100%;
    margin-top: 15px;
  }
}

.change-password {
  color: white;
  text-decoration: underline;
  cursor: pointer
}

.profile-container {
  text-align: center;
  min-height: 100vh;
  min-width: 100vw;

  .profile-content {
    margin: auto;
    padding: 20px;
    @media (min-width: 576px) {
      width: 450px;
    }
  }
  .error-msg {
    color: #fff;
    padding: 6px;
    border-radius: 5px;
    font-family: "Roboto";
    font-size: 15px;
    @media (max-width: 576px) {
      font-size: 11px;
    }
    p {
      margin: 0;
    }
    @include customize(theme) {
      background-color: getValue(error);
    }
  }
}
