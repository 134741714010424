@import "../../constants/themes.scss";

.operation-content {
  padding: 0;
  height: calc(100vh - 64px);
}
.tab-menu {
  .ant-tabs-nav {
    @include customize(theme) {
      background-color: getValue(theme-dark-0);
    }
    .ant-tabs-nav-wrap {
      .ant-tabs-ink-bar {
        @include customize(theme) {
          border-bottom: 5px solid getValue(buttonPrimaryBackground);
        }
      }
    }
    .ant-tabs-nav-list {
      margin-bottom: 1px;
      .ant-tabs-tab {
        @include customize(theme) {
          background-color: getValue(theme-dark-0);
          color: getValue(backgroundLight-2);
        }
        &:not(.ant-tabs-tab-active) {
          &:focus,
          &:hover {
            @include customize(theme) {
              background-color: getValue(theme-dark-3);
              .ant-tabs-tab-btn {
                color: getValue(backgroundLight-2);
              }
            }
          }
          .ant-tabs-tab-btn {
            opacity: 40%;
          }
        }
        &.ant-tabs-tab-active {
          @include customize(theme) {
            background-color: getValue(theme-dark-3);
            .ant-tabs-tab-btn {
              color: getValue(backgroundLight-2);
            }
          }
        }
      }
    }
  }
  .ant-tabs-content-holder {
    min-height: 264px;
    height: calc(100vh - 150px);
    overflow: auto;
    @include customize(theme) {
      background-color: getValue(backgroundLight-1);
    }
  }
}
