@import "../../../constants/themes.scss";

.emat-input {
  font-weight: 500;
  font-family: "Roboto";
  font-size: 20px;
  line-height: 23.44px;

  @media (max-width: 576px) {
    font-size: 16px;
  }
  input {
    height: 100% !important;
  }
  &:not(.ant-checkbox-wrapper, .ant-switch, .ant-radio-wrapper, .ant-select),
  &.ant-select .ant-select-selector {
    // min-width: 180px;
    height: 55px;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    @include customize(theme) {
      background-color: getValue(inputBackground);
      border: 0.3px solid getValue(inputBorder-2);
    }
    @media (max-width: 576px) {
      height: 40px;
    }
  }
  &::placeholder,
  input::placeholder,
  input,
  .ant-select-selection-placeholder {
    font-family: "Roboto";
    font-size: 20px;
    background-color: inherit;
    @include customize(theme) {
      color: getValue(theme-dark-8);
    }
    @media (max-width: 576px) {
      font-size: 16px;
    }
  }
  &.ant-select.ant-select-focused:not(.ant-select-disabled) .ant-select-selector {
    box-shadow: none;
    @include customize(theme) {
      background-color: getValue(inputBackground);
      border: 0.3px solid getValue(inputBorder-2);
    }
  }
  .ant-select-selector {
    .ant-select-selection-item {
      margin: auto;
    }
    .ant-select-selection-placeholder {
      align-self: center;
      text-align: left;
    }
    &:hover {
      transition: all 0.3s;
      border-right-width: 1px !important;
    }
  }
  &.ant-checkbox-wrapper {
    font-weight: 500;
    font-family: "Roboto";
    font-size: 20px;

    .ant-checkbox {
      height: 25px;
      border-radius: 2px;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);

      .ant-checkbox-inner {
        height: 25px;
        width: 25px;
        margin-bottom: -3px;
        @include customize(theme) {
          border: 0.3px solid getValue(inputBorder-2);
        }
        &:after {
          width: 8px;
          height: 15px;
          top: 44%;
        }
      }
      &.ant-checkbox-checked .ant-checkbox-inner {
        @include customize(theme) {
          background-color: getValue(buttonPrimaryBackground);
        }
      }
    }
  }
  &.ant-switch {
    @include customize(theme) {
      background-color: getValue(theme-dark-8);
    }
    &.ant-switch-checked {
      @include customize(theme) {
        background-color: getValue(theme-dark-8);
      }
    }
    .ant-switch-handle:before {
      @include customize(theme) {
        background-color: getValue(buttonPrimaryBackground);
      }
    }
  }
  &.ant-input-affix-wrapper-disabled input::placeholder,
  &.ant-input-affix-wrapper-disabled input {
    color: rgba(0, 0, 0, 0.25);
  }
  &.ant-radio-wrapper {
    font-weight: 500;
    font-family: "Roboto";
    font-size: 16px;
    @media (min-width: 576px) {
      font-size: 20px;
    }

    .ant-radio {
      &-checked {
        .ant-radio-inner:after {
          transform: scale(1);
          @include customize(theme) {
            background-color: getValue(primary-1);
          }
        }
      }
      &-inner {
        $radioSize: 18px;
        width: $radioSize;
        height: $radioSize;
        @include customize(theme) {
          border-color: getValue(inputBorder-1);
        }

        &:after {
          width: $radioSize;
          height: $radioSize;
          margin-top: calc(($radioSize / 2) * -1);
          margin-left: calc(($radioSize / 2) * -1);
        }
      }
    }
  }
}
