.operations-group-form {
  display: flex;

  .op-input {
    width: 100%;
    padding-inline: 10px;
  }

}

.submit-button{
    margin-left: 10px;
    min-width: 100px;
}