@import "../../../constants/themes.scss";

.summary-container {
  padding: 60px;
  text-align: center;
  padding-top: 100px;
  margin-top: -35px;

  .arrow {
    font-size: 24px;
    position: absolute;
    top: 18px;
    left: 20px;
    cursor: pointer
  }

  @media (max-width: 576px) {
    padding: 30px;
    padding-top: 100px;
  }

  h1 {
    margin-bottom: 20px;
  }

  .summary-values {
    max-width: 350px;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 60px;

    .ant-row:hover .value {
      overflow: visible;
    }

    .ant-col {
      text-align: left;
    }

    .label {
      font-family: "Roboto Bold";
      font-weight: 700;
      font-size: 20px;

      @media (max-width: 576px) {
        font-size: 16px;
      }

      &.upper {
        text-transform: uppercase;
      }
    }

    .value {
      font-family: "Roboto";
      font-weight: 400;
      font-size: 20px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      @media (max-width: 576px) {
        font-size: 16px;
      }

      line-height: 23.44px;
      margin-top: 3px;
    }

    &.close {
      margin-top: 0;
      margin-bottom: 30px;
    }
  }

  #edit {
    margin-top: 25px;
  }

  &.close {
    @include customize(theme) {
      background-color: getValue(theme-dark-2);
    }

    .ant-modal-body,
    .close-operations,
    .close-operations .eMat-table #contra-positions thead .ant-table-cell,
    .buttons-container {
      max-width: 363px;
      margin: auto;

      @include customize(theme) {
        background-color: getValue(theme-dark-2);
      }
    }

    .close-operations {
      padding-top: 30px;

      .eMat-table {
        margin-top: 5px;
        margin-bottom: 30px;

        #contra-positions {
          max-width: 363px;
          margin: auto;

          thead .ant-table-cell {
            color: white;

            .ant-checkbox-wrapper {
              display: none;
            }
          }

          tbody {
            tr {
              td.ant-table-cell {
                font-family: "Roboto Medium";
                font-weight: 500;

                @include customize(theme) {
                  color: getValue(theme-dark-9);
                }
              }

              @include customize(theme) {
                &:not(.odd) {
                  background-color: getValue(backgroundLight-3);
                  color: getValue(theme-dark-9);
                }
              }

              td:first-child {
                padding-left: 16px;
                padding-right: 0;
              }

              .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
                @include customize(theme) {
                  background-color: getValue(buttonPrimaryBackground);
                  border-color: getValue(buttonPrimaryBackground);
                }
              }
            }
          }
        }
      }
    }

    .error-msg {
      max-width: 363px;
      margin: auto;
      color: #fff;
      padding: 6px;
      border-radius: 5px;
      font-family: "Roboto";
      font-size: 15px;
      margin-bottom: 30px;

      @media (max-width: 576px) {
        font-size: 11px;
      }

      p {
        margin: 0;
      }

      @include customize(theme) {
        background-color: getValue(error);
      }
    }
  }

  .fail-summary-container {
    text-align: center;

    h1 {
      margin: auto;
      margin-top: 30px;
      margin-bottom: 60px;
      max-width: 280px;
    }

    .buttons-container {
      padding-bottom: 50px;
    }
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
  }

  .auto-cancellation {
    p {
      padding-left: 1rem;
      font-family: "Roboto";
      font-weight: 400;
      font-size: 20px;
    }

    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2rem;
  }
}