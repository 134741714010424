@import "../../../constants/themes.scss";

.issued-orders-container {
  height: calc(100vh - 64px);
}
.count-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.count-issued-orders {
  color: #5e5d5d;
  font-weight: 600;
  font-family: "Roboto";
}

.label,
.value-orders,
.value-instrument {
  font-family: "Roboto";
  font-weight: 600;
  color: #5e5d5d;
  font-size: 20px;
  margin-top: 4px;
  margin-bottom: 4px;
  @media (max-width: 576px) {
    font-size: 16px;
  }
}

.value-summary {
  font-family: "Roboto";
  font-weight: 500;
  color: #ffffff;
  font-size: 20px;
  margin-top: 4px;
  margin-bottom: 4px;
  @media (max-width: 576px) {
    font-size: 16px;
  }
}

.value-summary-instrument {
  display: block;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &:hover {
    overflow: visible;
  }
}

.label {
  color: #c4c4c4;
}

.value-orders {
  font-weight: 500;
}

.card-issued-orders {
  margin-top: 10px;
  margin-bottom: 10px;
}

.group-button-issued-orders {
  @media (min-width: 576px) {
    float: left;
  }
}

.ant-card-body {
  padding: 20px;
  height: 100%;
  flex-direction: column;
  display: flex;
}

.order-card {
  height: 100%;
}

.col-order {
  margin-top: 10px;
  margin-bottom: 10px;
}

.filter-received {
  width: 200px;
  min-width: 100px;
}

.actions-buttons {
  margin-top: 20px;
  #delete {
    margin-right: 45px;
  }
}

.table-summary table {
  text-align: center;
  & th {
    text-align: center;
  }
}

.range-elipsis {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &:hover {
    overflow: visible;
  }
}
