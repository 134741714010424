@import "../../../constants/themes.scss";

.ant-btn.emat-button {
  border-radius: 5px;
  height: 55px;
  font-family: "Roboto";
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  box-shadow: none;
  @media (max-width: 576px) {
    font-size: 16px;
    height: 40px;
  }
  &:not(.ant-btn-link) {
    text-transform: uppercase;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  }
  &.ant-btn-primary {
    @include customize(theme) {
      background-color: getValue(buttonPrimaryBackground);
      border-color: getValue(buttonPrimaryBackground);
      color: getValue(buttonPrimaryColor);
    }
    &.danger {
      @include customize(theme) {
        // change-color($color: #c23e3e, $lightness: 0%);
        background-color: getValue(error);
        border-color: getValue(error);
      }
    }
    &:disabled {
      background-color: #d3d3d3;
      border-color: #d3d3d3;
      color: #5e5d5d;
    }
  }
  &.ant-btn-link {
    font-weight: normal;
    @include customize(theme) {
      color: getValue(linkColor);
    }
  }
  &.ant-btn-text {
    box-shadow: none;
    @include customize(theme) {
      color: getValue(buttonTextColor);
      &:hover:not([disabled]) {
        border: 1px solid getValue(buttonTextBorderHover);
      }
    }
    &.text-secondary {
      padding: 0;
      text-transform: unset;
      color: #5e5d5d;
      background-color: inherit;
      border: 1px rgba($color: #ffffff, $alpha: 0);
      height: 30px;
      &:disabled {
        color: #a2a2a2;
      }
      &:hover:not([disabled]) {
        border: 1px rgba($color: #ffffff, $alpha: 0);
      }
      &.danger {
        @include customize(theme) {
          color: getValue(error);
        }
      }
    }
  }
}

.ant-modal-footer .ant-modal-footer .emat-button .ant-btn-primary {
  @include customize(theme) {
    background: getValue(buttonPrimaryBackground) !important;
    border-color: getValue(buttonPrimaryBackground);
    color: getValue(buttonPrimaryColor);
  }
}
