@import "../../constants/themes.scss";

.alerts-container {
  position: relative;

  .alert-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h2 {
      font-weight: 700;
    }

    #add-alert {
      padding-right: 20px;
    }
  }

  .buttons-container {
    button.text-secondary {
      padding: 3;
      margin-right: 10px;

      &#delete-alert {
        margin-right: 45px;
      }
    }
    button.operator {
      margin-left: 0px;
      margin-top: 10px;
      @media (min-width: 576px) {
        margin-left: 10px;
      }
    }
  }

  .alert-form-modal {
    #operation-type-item {
      text-align: center;
    }

    #grain-select {
      align-self: center;
    }

    h2 {
      font-size: 18px;
      font-weight: 600;

      @media (min-width: 576px) {
        font-size: 22px;
      }
    }

    @include customize(theme) {
      h2 {
        color: getValue(theme-dark-8);
        text-align: center;
      }

      .ant-divider {
        margin-left: -24px;
        color: getValue(primary-1);
        border: 2.5px solid getValue(primary-1);
        width: calc(100% + 48px);
      }
    }

    #alert-form {
      padding: 0;

      @media (min-width: 576px) {
        padding: 0 40px;
      }
    }
  }

  .filters-alerts {
    width: 200px;
    min-width: 100px;
    margin: 5px;
    @media (max-width: 576px) {
      width: auto;
    }
  }

  .button-alerts {
    margin-left: 5px;
  }

  .filter {
    display: flex;
    flex-direction: row;
    @media (max-width: 576px) {
      flex-direction: column;
      text-align: start;
      margin-bottom: 10px;
    }
  }
}
