@import "../../constants/themes.scss";

.call-put-modal {
  padding-bottom: 0;

  @media (min-width: 577px) {
    width: 570px;
  }

  .arrow {
    font-size: 24px;
    position: absolute;
    top: 18px;
    left: 20px;
    cursor: pointer
  }


  &.background {
    color: white;
    .ant-modal-close-x {
      color: white;
    }
    &.close {
      .ant-modal-content {
        background-color: #2d5675;
      }
    }
    .ant-modal-content {
      background-color: #1e3a4f;
    }
  }

  .ant-modal-content {
    .ant-modal-body {
      padding: 0;

      .eMat-tabs.operations-tabs {
        margin-top: 20px;

        .ant-tabs-nav,
        .ant-tabs-tab {
          background: inherit;

          .ant-tabs-tab-btn {
            text-transform: uppercase;
            @include customize(theme) {
              color: getValue(theme-dark-8) !important;
            }
          }
        }
        .ant-tabs-nav-wrap {
          .ant-tabs-nav-list {
            width: 100%;

            .ant-tabs-tab {
              width: 50%;
              justify-content: center;
              background-color: inherit;

              .ant-tabs-tab-btn {
                opacity: 1;
              }

              &.ant-tabs-tab-active,
              &:not(.ant-tabs-tab-active):hover {
                background-color: #e9fcf9;
              }
            }
          }
        }

        .ant-tabs-content-holder {
          background-color: inherit;
          padding: 0;
        }

        .ant-tabs-nav::before {
          border: none;
        }
      }
    }
  }
}
