@import "./constants/themes.scss";

/* Fonts */
//TODO add font-weight to all fonts. Declare all as Roboto soy we call them al as the same font, just change the font-weight when needed. Also add font-display: swap; to all fonts in order to use fallback font until actual font is loaded. Declare a second font for the fallback font. Some similar.
@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Roboto Bold";
  src: url("./assets/fonts/Roboto/Roboto-Bold.ttf");
}

@font-face {
  font-family: "Roboto Light";
  src: url("./assets/fonts/Roboto/Roboto-Light.ttf");
}

@font-face {
  font-family: "Roboto Medium";
  src: url("./assets/fonts/Roboto/Roboto-Medium.ttf");
}

body {
  min-width: 300px;
}
.App {
  font-family: "Roboto";
}

.background {
  @include customize(theme) {
    &.dark {
      background-color: getValue(theme-dark-2);
    }
    &.light-2 {
      background-color: getValue(backgroundLight-2);
    }
    &.light-1 {
      background-color: getValue(backgroundLight-1);
    }
    &.light-3 {
      background-color: getValue(backgroundLight-4);
    }
  }
}

.main-content {
  position: relative;
  padding: 20px;
  min-height: 350px;
  height: calc(100vh - 64px);
  overflow: auto;
  @media (min-width: 576px) {
    padding: 40px;
  }
}

h1 {
  font-size: 25px;
  color: inherit;
  margin: 0;
  @media (max-width: 576px) {
    font-size: 21px;
  }
}

h2 {
  font-size: 23px;
  color: inherit;
  margin: 0;
  @media (max-width: 576px) {
    font-size: 19px;
  }
}

h3 {
  font-size: 20px;
  color: inherit;
  margin: 0;
  @media (max-width: 576px) {
    font-size: 16px;
  }
}
p {
  margin: 0;
}

.divider-container {
  position: relative;
  margin-left: 24px;
  margin-right: 25px;
  border-radius: 5px;
  .divider {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    @include customize(theme) {
      border-left: 3px solid getValue(theme-dark-3);
    }
  }
}

.mb-16 {
  margin-bottom: 16px;
}

.pointer {
  cursor: pointer;
}

.ant-spin {
  position: absolute;
  top: 49%;
  left: 49%;
}