.operations {
  align-items: center;

  .operation {
    align-items: center;
    margin-inline: 5px;
    // border-style: solid;
    border-width: 2px;
    padding-inline: 5px;
    padding-block: 2px;
    border-radius: 5px;
    display: flex;
    color: #5e5d5d;
  }
}

.submit-button {
  margin-left: 10px;
  min-width: 100px;
}
