@import "../../../constants/themes.scss";

.alert-container {
  padding: 15px;
  display: flex;
  position: relative;
  justify-content: space-around;
  flex-direction: column;
  padding: 15px;
  border-radius: 5px;
  height: 100%;
  padding-bottom: 70px;

  @media (min-width: 576px) {
    padding: 25px;
    padding-bottom: 70px;
  }
  @include customize(theme) {
    color: getValue(backgroundLight-2);
    background: getValue(theme-dark-1);
  }
  #title-container {
    font-weight: 500;
    display: flex;
    svg {
      margin-right: 20px;
    }
  }
  .alert-count,
  .alert-info {
    display: flex;
  }
  .alert-count {
    justify-content: space-between;
    #alert-count {
      font-family: "Roboto Medium";
      font-weight: 500;
      font-size: 20px;
      color: #305776;
      @media (max-width: 576px) {
        font-size: 16px;
      }
    }
  }
  .alert-info {
    margin-top: 15px;
    justify-content: space-around;
    h3 {
      font-weight: bold;
    }
    p {
      font-family: "Roboto";
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      @media (max-width: 576px) {
        font-size: 16px;
      }
    }
    .instrument {
      font-family: "Roboto Bold";
    }
  }
  #go-to-trade {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0 0 5px 5px;
  }
}
.alert-container {
  padding: 15px;
}
.temporal-info {
  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    h1 {
      font-weight: bolder;
      margin-right: 12px;
    }
    img {
      height: 30px;
    }
  }

  h3 {
    margin-top: 20px;
  }
}
