.eMat-table {
  background-color: inherit;
  .ant-table {
    overflow: auto;

    .ant-table-footer {
      background-color: inherit;
    }

    table {
      & > thead > tr:first-child th:first-child {
        border-top-right-radius: 2px;
      }
      & > thead > tr:first-child th:last-child {
        border-top-left-radius: 2px;
      }
      thead tr {
        th {
          box-shadow: none;
          font-family: "Roboto";
          font-size: 17px;
          font-weight: 700;
          border: none;
          &:not(:last-child):not(.ant-table-selection-column):not(
              .ant-table-row-expand-icon-cell
            ):not([colspan]):before {
            width: 0;
            height: 0;
          }
          @media (max-width: 576px) {
            font-size: 13px;
            padding: 5px;
          }
        }
      }

      tbody {
        tr td {
          box-shadow: none;
          border: none;
          font-family: "Roboto";
          font-size: 17px;
          font-weight: 500;
          padding: 12px 16px;
          @media (max-width: 576px) {
            font-size: 13px;
            padding: 10px;
          }
          .ant-checkbox-input:focus + .ant-checkbox-inner,
          .ant-checkbox-wrapper:hover .ant-checkbox-inner,
          .ant-checkbox:hover .ant-checkbox-inner {
            border-color: #1cc3a7;
          }
          .ant-checkbox {
            .antcheckbox-inner {
              background-color: #1cc3a7;
              border-color: #1cc3a7;
            }
          }
        }
      }
    }
  }
}
