@import "../../../constants/themes.scss";

.offline-page {
  background-color: #162f43;
  height: 100%;
  min-height: calc(100vh + 30px);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -30px;
  text-align: center;
  overflow: auto;
  .offline-info-container {
    .no-connection {
      position: relative;
      display: block;
      color: #1cc3a7;
      height: 120px;
      width: 175px;
      margin: auto;
      margin-bottom: 20px;
      .anticon-wifi svg {
        height: 120px;
        width: 120px;
      }
      .anticon-line {
        position: absolute;
        left: 18px;
        bottom: 2px;
        svg {
          font-size: 135px;
        }
      }
    }
    h1 {
      font-family: "Roboto Bold";
    }
    @media (max-width: 576px) {
      .no-connection {
        margin-bottom: 0px;
        .anticon-wifi svg {
          height: 105px;
          width: 105px;
        }
        .anticon-line {
          left: 24px;
          bottom: 15px;
          svg {
            font-size: 120px;
          }
        }
      }
    }
  }

  #redirect-last-page {
    margin-top: 25px;
  }
  .logo-container {
    margin-top: 40px;
    img {
      height: 35px;
    }
  }
  @media (min-width: 576px) {
    #redirect-last-page {
      margin-top: 35px;
    }
    .logo-container {
      margin-top: 50px;
      img {
        height: 45px;
      }
    }
  }
}
