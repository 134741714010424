@import "../../constants/themes.scss";

.error-modal {
    z-index: 999;
    .modal-content {
        @include customize(theme) {
            background-color: getValue(inputBackground);
        }
    }

    .text-danger {
        text-align: center;
        @include customize(theme) {
            color: var(--white) !important;
        }
    }
}

.modal-dialog {
    max-width: 600px;
}
