@import "../../../constants/themes.scss";

.change-password-container {
	.change-password-content {
		.buttons-container .ant-form-item-control-input-content {
			display: flex;
			flex-direction: column;
			align-content: center;
			margin-top: 50px;
			#cancel {
				order: 2;
			}
			#submit {
				order: 1;
			}
			button {
				width: 100%;
			}
			@media (min-width: 576px) {
				button {
					width: 200px;
				}
				flex-direction: row;
				justify-content: space-between;
				#cancel {
					order: 1;
				}
				#submit {
					order: 2;
				}
			}
		}
		.ant-result {
			padding: 0;
		}
		.ant-result {
			.ant-result-extra {
				margin-top: 45px;
			}
			.ant-result-subtitle {
				font-family: "Roboto Bold";
				font-size: 20px;
				color: white;
				@media(max-width:576px) {
					font-size: 16px;
				} 
			}
		}
	}
	.error-msg {
		color: #fff;
		padding: 6px;
		border-radius: 5px;
		font-family: "Roboto";
		font-size: 15px;
		@media(max-width:576px) {
			font-size: 11px;
		} 
		p {
			margin: 0;
		}
		@include customize(theme) {
			background-color: getValue(error);
		}
	}
}
