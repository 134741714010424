@import "../../constants/themes.scss";

.received-orders-container {
  @include customize(theme) {
    color: getValue(theme-dark-9);
    background: getValue(backgroundLight-1);
  }

  font-size: 20px;
  display: flex;
  position: relative;
  flex-direction: column;

  @media (max-width: 576px) {
    font-size: 16px;
  }
}

.filter-received {
  width: 200px;
  min-width: 100px;
}

.operation-states {
  width: 300x;
  min-width: 200px;
}

.actions-buttons {
  margin-top: 20px;
}

.table-summary table {
  text-align: center;

  & th {
    text-align: center;
  }
}

.text-acctions {
  font-weight: 500;
  font-family: "Roboto";
  font-size: 20px;
  line-height: 23.44px;
}

.button-margins {
  margin-top: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
}

.range-elipsis {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &:hover {
    overflow: visible;
  }
}