@import "../../../constants/themes.scss";

.ant-modal.emat-modal {
  min-width: 300px;
  overflow: auto;
  @media (max-width: 576px) {
    max-width: 100vw !important;
    margin: 0 auto !important;
    width: 100% !important;
    max-height: 100vh;
    overflow: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: 0;
    .ant-modal-content {
      width: 100vw;
      min-width: 300px;
      min-height: 100vh;
      overflow: auto;
    }
  }
  .ant-modal-content {
    .ant-modal-body {
      padding-top: 35px;
    }
    .ant-modal-footer {
      border: none;
    }
  }
}
