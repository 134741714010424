.spinner {
	z-index: 5;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.3);
	background-size: cover;
	display: flex;
	min-height: 100vh;
	.spinner-container {
		margin: auto;
	}
}
