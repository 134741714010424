@import "../../../constants/themes.scss";
.call-put-form {
  // border: solid 2px blue;

  .strike-instrument {
    background-color: #fafafa;
    line-height: 21px;
    padding: 21px;
    text-align: center;

    @include customize(theme) {
      background-color: getValue(backgroundLight);
    }

    span {
      text-transform: uppercase;
      padding-right: 10px;
      @include customize(theme) {
        color: getValue(primary-1);
      }
    }
  }
}
